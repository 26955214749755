/**
 * @module SiFT
 */

import siftTracker from 'sift-web-sdk/standalone';
import config from 'config';
import Logger from 'lib/logger';

const DEV_API_KEY = 'ooOvsXHa136NHCZSbDYK69kJdf9cJs48baAUvKW7';
const PROD_API_KEY = 'tLloxdX9qY4BK7Mgm73Hq7MEHEUXvpFU8oKBIx2V';

const logger = new Logger('SiFT', { background: 'red' });
class SiFT {
  senderPlatform = '';

  eventMap = {
    'screen-viewed': (eventData) => {
      return {
        eventName: 'screen_viewed',
        eventPayload: eventData,
        eventSchema: 'stream/screen_viewed/1'
      };
    },
    'video-played': (eventData) => {
      return {
        eventName: 'video_played',
        eventPayload: {
          sender_platform: this.senderPlatform,
          ...eventData
        },
        eventSchema: 'stream/video_played/7'
      };
    }
  };

  userTypes = {
    default: 'Default',
    flex: 'Flex',
    introtv: 'IntroTV',
    university: 'University',
    stream: 'Stream',
    churned: 'Churn',
    smb: 'SMB'
  };

  accountStateMap = {
    'preactive': 'Pre-active',
    'active': 'Active',
    'preactive-timeout': 'Limited'
  };

  partnerAppMap = {
    'comcast': 'Stream',
    'videotron': 'HELIX TV',
    'cox': 'Contour',
    'rogers': 'Ignite TV',
    'shaw': 'Shaw TV'
  };

  /**
   * custom context contains the data in the 'stream/custom' schema.
   * Assume these default values until session is activated.
   */
  defaultCustomContext = {
    account_status: this.accountStateMap['active'],
    analytics_group: 'NA',
    custom_schema: 'stream/custom/5',
    connection: 'Out of Home',
    connection_type: 'None', // not available in this app
    device_type: 'TV',
    entitlements: 'NA',
    logged_in: 'No',
    ooh_filter_enabled: 'No',
    parental_controls: 'Disabled',
    remote_playback_device_connected: 'None',
    screen_reader: 'NA', // not available in this app
    switch_accessibility: 'NA', // not available in this app
    ssid: 'NA', // not available in this app
    university: 'None',
    x1_user_type: this.userTypes.default
  };

  /**
   * Build initialization object and initialize SiFT
   * @return {Promise}
   */
  async init() {
    await config.load();

    if (!config.siftTrackingEnabled) {
      return Promise.reject();
    }

    if (this.siftInitPromiseResolved) {
      return this._siftInitPromise;
    }

    const initData = {
      trackerInstName: 'siftTracker',
      uri: config.env === 'prod' ? 'collector.pabs.comcast.com/stream/prod' : 'collector-dev.sift.comcast.com/events/stream',
      appId: this.partnerAppMap[config.partner],
      forceSecureTracker: true,
      bufferSize: 10, // default
      bufferTTL: 0,
      randomDelay: 0,
      loglevel: 'error',
      httpRequest: function(xhrValue, body) {
        xhrValue.setRequestHeader('x-api-key', config.env === 'prod' ? PROD_API_KEY : DEV_API_KEY);
      },
      httpResponse: function(xhr, body) {
        if (xhr.status && body.length) {
          for (let i = 0; i < body.length; i++) {
            logger.log(`event logged: ${body[i].event_name} - ${body[i].event_id} - status: ${xhr.status}`);
          }
        }
      },
      global_context: {
        partner_id: config.partner,
        app_ver: config.version,
        platform: 'chromecast'
      },
      custom_context: this.defaultCustomContext
    };

    this._siftInitPromise = siftTracker.init(initData)
      .then(function(data) {
        this.siftInitPromiseResolved = true;
        logger.log('initialization successful');
      }.bind(this))
      .catch(function(error) {
        this.siftInitPromiseResolved = false;
        logger.log('Initialization failed');
      }.bind(this));

    return this._siftInitPromise;
  }

  /**
   * Once controlling session is activated, setup
   * SiFT custom content.
   * @param {object} session - result of session create request
   * @param {object} senderDetails - sender details from session create
   */
  onSessionActivated(session, senderDetails) {
    const tokenSummary = session && session.tokenSummary || {};
    const featuresResult = session && session.featuresResult || {};
    const entitlements = tokenSummary.entitlements && tokenSummary.entitlements.length && tokenSummary.entitlements.join(', ') || 'NA';

    this.senderPlatform = senderDetails && senderDetails.platform || 'NA';

    siftTracker.updateCustomObject({
      account_status: this.accountStateMap[tokenSummary.accountState],
      analytics_group: (featuresResult.features || {}).analyticsGroup || 'default',
      entitlements: entitlements,
      logged_in: 'Yes',
      x1_user_type: this.userTypes[tokenSummary.userType] || this.userTypes.default,
      connection: tokenSummary.inHomeStatus === 'in-home' ? 'In Home' : 'Out of Home',
      university: this.userTypes[tokenSummary.userType] === 'university' ? tokenSummary.partnerId : 'None',
      account_id: tokenSummary.xboAccountId,
      device_id: tokenSummary.deviceId,
      version: config.version
    });
  }

  /**
   * update one or more SiFT custom fields on the fly
   * @param {object} update - field(s):value(s) to be updated
   */
  updateCustomObject(update) {
    siftTracker.updateCustomObject(update);
  }

  /**
   * Construct event and pass to SiFT's tagEvent
   * @param {string} eventType - name of event
   * @param {object} eventDetail - event data
   * @return {Promise}
   */
  tagEvent(eventType, eventDetail) {
    if (!config.siftTrackingEnabled || !this.siftInitPromiseResolved) {
      return Promise.reject();
    }
    return siftTracker.tagEvent(this.eventMap[eventType](eventDetail))
      .then((res) => {
        logger.log('successful tagEvent: ', res);
        return res;
      })
      .catch((err) => {
        logger.log('tagEvent failed: ', err);
        return Promise.reject(err);
      });
  }
}

export default new SiFT();
export { SiFT };
